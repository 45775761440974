<template>
  <div class="recipe">
    <div class="header">
      <Logo class="logo" @click="goToHome" />
      <div class="page-container">
        <Previous :class="['prev-icn', currentPage === 1 ? 'disable' : '']" @click="previous" />
        <div class="page-numbers">{{ currentPage }} / {{ totalRecipes }}</div>
        <Next :class="['next-icn', currentPage === totalRecipes ? 'disable' : '']" @click="next" />
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="video-button" v-if="recipe.video !== 'tbd' && !isMobile" @click="isModalOpen = true">
          <img :src="getThumbnail(recipe.thumbnail)" />
          <VideoIcon class="vid-icn" />
        </div>
        <div class="video-button" v-if="recipe.video === 'tbd' && !isMobile">
          <img :src="getThumbnail(recipe.thumbnail)" />
        </div>
        <div class="livestream-container" v-if="isMobile">
          <iframe
            autoplay
            allow="autoplay; fullscreen"
            allowfullscreen
            width="900px"
            height="506.25px"
            :src="recipe.video"
          />
        </div>
      </div>
      <div class="right">
        <div class="title">{{ recipe.name }}</div>
        <div class="creator">Recommended By {{ recipe.creator }}</div>
        <ScrollPanel class="custombar1">
          <div class="scroll-container">
            <div class="ingredient-container">
              <div class="subtitle">Ingredients</div>
              <li class="bullet-list" v-for="ingredient in recipe.ingredients" :key="ingredient">{{ ingredient }}</li>
            </div>
            <div class="prep-container">
              <div class="subtitle">How to prepare</div>
              <div class="description" v-if="recipe.prepareDescription">{{ recipe.prepareDescription }}</div>
              <div v-if="recipe.preparationCustom" class="prep-custom">
                <div v-for="preparation in recipe.preparationCustom" :key="preparation.title">
                  <li class="prep-title">{{ preparation.title }}</li>
                  <div v-for="list in preparation.list" :key="list">
                    <li class="prep-list">{{ list }}</li>
                  </div>
                </div>
              </div>
              <div class="list" v-for="(step, index) in recipe.steps" :key="step">
                <span v-if="recipe.steps.length > 1" class="step">STEP {{ index + 1 }} </span>
                <span>{{ step }}</span>
              </div>
            </div>
            <div class="mocktail-container">
              <div class="subtitle">Make it a mocktail</div>
              <div class="list" v-for="(step, index) in recipe.mocktail" :key="step">
                <span v-if="recipe.mocktail.length > 1" class="step">STEP {{ index + 1 }} </span>
                <span
                  >{{ step }}
                  <a v-if="recipe.mocktailLink" :href="recipe.mocktailLink" target="_blank">
                    {{ recipe.mocktailLinkText }}</a
                  >
                </span>
              </div>
            </div>
            <div class="glass-container">
              <div class="subtitle">Glass recommendation</div>
              <div class="list" v-for="glass in recipe.glassware" :key="glass">{{ glass }}</div>
            </div>
          </div>
        </ScrollPanel>
      </div>
    </div>
    <footer>
      <a href="https://www.24g.com/" target="_blank" class="footer-link"
        >Shared with love from <span class="bold">24<span class="orange">G</span></span></a
      >
    </footer>
    <Modal class="modal-screens" v-show="isModalOpen">
      <template v-slot:body class="body">
        <div class="close" @click="(isModalOpen = false), endVideo()"><Close /></div>
        <div class="livestream-container">
          <iframe
            autoplay
            allow="autoplay; fullscreen"
            allowfullscreen
            width="900px"
            height="506.25px"
            :src="recipe.video"
            id="video"
          />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import recipes from '@/assets/json/recipes.json';
import Logo from '@/assets/icons/24g_logo.svg';
import Next from '@/assets/icons/arrow_next.svg';
import Previous from '@/assets/icons/arrow_previous.svg';
import Modal from '@/components/Modal.vue';
import Close from '@/assets/icons/exit_icon.svg';
import VideoIcon from '@/assets/icons/video_icon.svg';
import ScrollPanel from '@/components/ScrollPanel.vue';

export default {
  name: 'Recipe',
  components: {
    Logo,
    Next,
    Previous,
    VideoIcon,
    Modal,
    Close,
    ScrollPanel,
  },
  data() {
    return {
      recipes,
      isModalOpen: false,
    };
  },
  methods: {
    goToHome() {
      this.$router.push({
        name: 'home',
      });
    },
    next() {
      if (this.currentPage !== this.totalRecipes) {
        const nextRecipe = this.recipes.recipes[this.recipe.id].routeName;
        this.$router.push(`/${nextRecipe}`);
      }
    },
    previous() {
      if (this.currentPage !== 1) {
        const prevRecipe = this.recipes.recipes[this.recipe.id - 2].routeName;
        this.$router.push(`/${prevRecipe}`);
      }
    },
    getThumbnail(image) {
      if (image) {
        // eslint-disable-next-line import/no-dynamic-require
        return require(`@/assets/images/${image}.png?external`);
      }
      return require('@/assets/images/Episode_1.png?external');
    },
    endVideo() {
      const video = document.getElementById('video');

      const source = video.src;
      video.src = '';
      video.src = source;
    },
  },
  computed: {
    recipe() {
      const completeRecipe = this.recipes.recipes.filter((recipe) => recipe.routeName === this.$route.params.recipe);
      return completeRecipe[0];
    },
    totalRecipes() {
      return this.recipes.recipes.length;
    },
    currentPage() {
      if (this.recipe) {
        return this.recipe.id;
      }
      return 1;
    },
    isMobile() {
      if (window.screen.width <= 1100) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.recipe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  margin-top: 50px;
  .page-container {
    display: flex;
    .page-numbers {
      width: 50px;
      margin: 0 16px;
      font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
      font-weight: 500;
      letter-spacing: 1.6px;
      white-space: nowrap;
    }
  }
  .logo {
    cursor: pointer;
  }
}
.content {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-top: 30px;
  @media only screen and (min-width: 720px) {
    padding: 0 94px;
  }

  @media only screen and (min-width: 1100px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
.left {
  position: relative;
  display: flex;
  order: 2;
  margin-top: 30px;
  @media only screen and (min-width: 1100px) {
    order: 1;
    width: 40%;
    margin-top: 0;
  }
  .video-button {
    position: relative;
    display: flex;
    width: 100%;
    margin: 20px 0;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      border-radius: 30px;
    }
  }
  .vid-icn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    margin: auto;
  }
}
.right {
  display: flex;
  flex-direction: column;
  order: 1;
  padding: 25px 40px;
  text-align: left;
  background: #00000080;
  border-radius: 20px;
  box-shadow: 0 3px 6px #0000004d;
  @media only screen and (min-width: 1100px) {
    order: 2;
    width: 50%;
  }
  @media only screen and (min-width: 1600px) {
    order: 2;
    width: 50%;
  }
  .title {
    z-index: 1;
    font-family: 'Writable Story', Helvetica, Arial, sans-serif;
    font-size: 9.5vw;
    @media only screen and (min-width: 720px) {
      font-size: 6.5vw;
    }

    @media only screen and (min-width: 1100px) {
      font-size: 65px;
      white-space: nowrap;
    }
  }
  .creator {
    z-index: 1;
    padding-bottom: 30px;
    margin-top: 10px;
    font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    opacity: 0.5;
  }
  .scroll-container {
    z-index: 1;
  }
  .ingredient-container {
    padding-bottom: 20px;
    border-bottom: 1px solid #393c3e;
    li {
      margin-bottom: 10px;
      list-style: circle;
    }
  }
  .subtitle {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 1.05px;
  }
  .prep-container {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #393c3e;
    .list {
      display: flex;
      margin-bottom: 10px;
      .step {
        margin-right: 10px;
        font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 1.6px;
        white-space: nowrap;
      }
    }
    .prep-custom {
      margin: 10px 0;
    }
    .prep-title {
      font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      list-style: circle;
    }
    .prep-list {
      padding-left: 35px;
      margin-bottom: 10px;
    }
  }
  .mocktail-container {
    padding-bottom: 20px;
    margin-top: 20px;
    border-bottom: 1px solid #393c3e;
    .list {
      display: flex;
      margin-bottom: 10px;
      .step {
        margin-right: 10px;
        font-family: 'Brandon Grotesque Medium', Helvetica, Arial, sans-serif;
        font-weight: 500;
        letter-spacing: 1.6px;
        white-space: nowrap;
      }
    }
    a {
      color: #faa617;
    }
  }
  .glass-container {
    margin-top: 20px;
  }
}
.modal-body {
  display: flex;
  flex-direction: column;
  .close {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}
.livestream-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  @media only screen and (min-width: 1100px) {
    padding-bottom: 51.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
.custombar1 {
  @media only screen and (min-width: 1100px) {
    height: 300px;
  }
  @media only screen and (min-width: 1600px) {
    height: 400px;
  }
}
</style>
